import React from "react";
import { FlexDiv, Text } from "basecomponents";

export default function RecentResults({
  recentResults,
}: {
  recentResults: { roll: number; isWin: boolean }[];
}) {
  return (
    <FlexDiv
      backgroundColor="brand.5"
      borderRadius={10}
      marginBottom={60}
      h={30}
      padding={4}
      overflow="hidden"
    >
      {recentResults.map(({ roll, isWin }) => (
        <FlexDiv
          key={roll}
          backgroundColor="brand.3"
          padding="4px 6px"
          borderRadius={8}
          marginRight={{ notLastChild: 4 }}
        >
          <Text color={isWin ? "green.2" : "red.2"} fontSize={14}>
            {roll}
          </Text>
        </FlexDiv>
      ))}
    </FlexDiv>
  );
}
