import { useEffect, useState, useRef } from "react";
import useAppStore from "./useAppStore";
import { AllBetsResult, GameResult, Leaderboard } from "types";

export default function useBettingResults() {
  const socket = useAppStore((state) => state.socket);
  const [allBets, setAllBets] = useState<AllBetsResult[]>([]);
  const [gameResult, setGameResult] = useState<GameResult>(null);
  const [leaderboard, setLeaderboard] = useState<Leaderboard>(null);
  const txId = useRef<string>(null);
  const nextLeaderboard = useRef<Leaderboard>(null);

  function updateAllBets(result: GameResult) {
    setAllBets([
      {
        asset: result.asset,
        round: result.round,
        user: result.user,
        payout: result.payoutAmount,
        multiply: result.multiply,
        amount: result.amount,
        txhash: null,
      },
      ...allBets.slice(0, 9),
    ]);
    setLeaderboard(nextLeaderboard.current);
    nextLeaderboard.current = null;
  }
  function onAnimationFinished() {
    updateAllBets(gameResult);
    setGameResult(null);
    txId.current = null;
  }
  useEffect(() => {
    socket.once("allbets", (bets) => {
      if (!allBets.length) {
        setAllBets(bets);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("payout", (payout) => {
      //update allBets txhash
      const newAllBets = [...allBets];
      const index = newAllBets.findIndex(({ round }) => round === payout.round);
      newAllBets[index] = { ...newAllBets[index], txhash: payout.txhash };
      setAllBets(newAllBets);
    });
    socket.on("gameresult", (result: GameResult) => {
      if (result.depositTxid === txId.current) {
        setGameResult(result);
      } else {
        updateAllBets(result);
      }
    });
    return () => {
      socket.off("payout");
      socket.off("gameresult");
    };
  }, [allBets]);

  useEffect(() => {
    socket.on("leaderboard", (newLeaderboard) => {
      nextLeaderboard.current = newLeaderboard;
      //initial setting
      if (!leaderboard) {
        setLeaderboard(newLeaderboard);
      }
    });
    return () => {
      socket.off("leaderboard");
    };
  }, [leaderboard]);

  return {
    allBets,
    gameResult,
    setTxId: (newTxId) => (txId.current = newTxId),
    onAnimationFinished,
    leaderboard,
  };
}
