import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import ControlButton from "./common/ControlButton";
import { DiceSide } from "types";
import { diceSideLabels } from "../../constants";
import ControlContainer from "./common/ControlContainer";
import ControlInput from "./common/ControlInput";

import SwapIcon from "jsx:icons/swap.svg";

export default function Roll({
  selectedSide,
  setSelectedSide,
  roll,
  setRoll,
}: {
  selectedSide: DiceSide;
  setSelectedSide: (selectedSide: DiceSide) => void;
  roll: string;
  setRoll: (roll: number) => void;
}) {
  const [inputValue, setInputValue] = useState(roll);
  const [isEditing, setIsEditing] = useState(false);

  useLayoutEffect(() => {
    setInputValue(roll);
  }, [isEditing, roll]);

  return (
    <FlexDiv flex={1} column>
      <Text fontSize={12} color="brand.2" marginBottom={4}>
        ROLL {diceSideLabels[selectedSide]}
      </Text>
      <ControlContainer>
        <ControlInput
          value={inputValue}
          onConfirm={(newRoll) => setRoll(+newRoll)}
          setIsEditing={setIsEditing}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          type="number"
        />
        <ControlButton
          marginLeft="auto"
          h="100%"
          borderRadius="0px 4px 4px 0px"
          onClick={() => setSelectedSide(selectedSide === "UP" ? "DOWN" : "UP")}
        >
          <SVG as={SwapIcon} />
        </ControlButton>
      </ControlContainer>
    </FlexDiv>
  );
}
