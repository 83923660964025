import styled, {
  compose,
  height,
  HeightProps,
  space,
  SpaceProps,
  transform,
  TransformProps,
  width,
  WidthProps,
} from "@xstyled/styled-components";

const composedStyled = compose(width, height, space, transform);

export default styled.svg.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !composedStyled.meta.props.includes(prop) && defaultValidatorFn(prop),
})<WidthProps & HeightProps & SpaceProps & TransformProps>`
  flex-shrink: 0;
  ${compose(width, height, space, transform)}
`;
