import React from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import { assetOptions } from "../../constants";
import Big from "big.js";
import ControlContainer from "./common/ControlContainer";

export default function Payout({
  selectedAsset,
  multiplier,
  betAmount,
}: {
  selectedAsset: keyof typeof assetOptions;
  multiplier: number;
  betAmount: Big;
}) {
  return (
    <FlexDiv column flex={1}>
      <Text fontSize={12} color="brand.2" marginBottom={4}>
        PAYOUT
      </Text>
      <ControlContainer>
        <SVG as={assetOptions[selectedAsset].icon} marginRight={16} w={24} />
        <Text fontSize={20} bold color="white">
          ${betAmount.times(multiplier).toFixed()}
        </Text>
      </ControlContainer>
    </FlexDiv>
  );
}
