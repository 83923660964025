import styled, {
  compose,
  layout,
  LayoutProps,
  sizing,
  SizingProps,
} from "@xstyled/styled-components";

export default styled.img<SizingProps & LayoutProps>`
  ${compose(sizing, layout)}
`;
