import { Button } from "basecomponents";
import styled from "@xstyled/styled-components";

export default styled(Button)`
  background-color: brand.4;
  width: 48px;
  color: brand.2;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    background-color: brand.5;
  }
`;
