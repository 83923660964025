import { AppTheme } from "./types/styled";

const theme: AppTheme = {
  colors: {
    brand: {
      2: "#9893CB",
      3: "#3E1985",
      4: "#2F1268",
      5: "#170834",
    },
    green: {
      2: "#99FFF3",
    },
    red: {
      2: "#FF5F9A",
    },
  },

  fonts: {
    regular: "AvertaRegular",
    bold: "AvertaBold",
  },
  screens: {
    mobile: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
  },
  states: {
    notLastChild: "&:not(:last-child)",
  },
};
export default theme;
