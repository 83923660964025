import React from "react";
import styled, {
  color,
  ColorProps,
  compose,
  fontSize,
  FontSizeProps,
  getFont,
  getFontSize,
  space,
  SpaceProps,
  style,
  th,
} from "@xstyled/styled-components";

const fontBoldUtility = style({
  prop: "bold",
  themeGet: getFont,
});

export default styled.span<
  FontSizeProps & ColorProps & SpaceProps & { bold?: boolean }
>`
  font-family: ${(props) => th.font(props.bold ? "bold" : "regular")};
  line-height: ${(props) => props.fontSize as number}px;

  ${compose(fontSize, color, space, fontBoldUtility)}
`;
