import React, { useEffect, useState } from "react";
import { Button, SVG } from "basecomponents";
import { Howler } from "howler";

import AudioOnIcon from "jsx:icons/sound-on.svg";
import AudioOffIcon from "jsx:icons/sound-off.svg";

function loadSoundDisabled() {
  let soundDisabled = localStorage.getItem("sound");
  if (soundDisabled) {
    soundDisabled = JSON.parse(soundDisabled);
  }
  return soundDisabled;
}

export default function SoundToggle() {
  const [soundDisabled, setSoundDisabled] = useState(
    loadSoundDisabled() || false
  );

  useEffect(() => {
    Howler.mute(soundDisabled);
  }, [soundDisabled]);

  return (
    <Button
      padding={10}
      backgroundColor="brand.4"
      borderRadius={10}
      onClick={() => setSoundDisabled(!soundDisabled)}
    >
      <SVG as={soundDisabled ? AudioOffIcon : AudioOnIcon} />
    </Button>
  );
}
