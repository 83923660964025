import React from "react";
import { FlexDiv, SVG, Button } from "basecomponents";
import SoundToggle from "./SoundToggle";
import { Leaderboard as LeaderboardType } from "types";
import Leaderboard from "./Leaderboard";

import Logo from "images/logo.png";
import InfoIcon from "jsx:icons/info.svg";

export default function Header({
  leaderboard,
}: {
  leaderboard: LeaderboardType;
}) {
  return (
    <FlexDiv
      justifyContent="space-between"
      alignItems="flex-start"
      marginBottom={{ tablet: 46 }}
      w="100%"
      padding={{ mobile: 16, tablet: "26px 26px 0" }}
    >
      <img src={Logo} style={{ width: 96 }} />
      <FlexDiv>
        <Leaderboard leaderboard={leaderboard} />
        <Button
          padding={10}
          backgroundColor="brand.4"
          borderRadius={10}
          marginRight={4}
        >
          <SVG as={InfoIcon} />
        </Button>
        <SoundToggle />
      </FlexDiv>
    </FlexDiv>
  );
}
