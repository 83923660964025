import SolanaIcon from "jsx:icons/currencies/solana.svg";
import USDCIcon from "jsx:icons/currencies/USDC.svg";
import PIPIcon from "jsx:icons/currencies/PIP.svg";

export const diceSideLabels = {
  UP: "OVER",
  DOWN: "UNDER",
};
export const assetOptions = {
  SOL: { label: "SOL", icon: SolanaIcon },
  USDC: { label: "USDC", icon: USDCIcon },
  PIP: { label: "PIP", icon: PIPIcon },
};
export const possibleRolls = {
  min: 0,
  max: 9999,
  count: 10000,
};
