import styled, {
  backgroundColor,
  BackgroundColorProps,
  borderRadius,
  BorderRadiusProps,
  compose,
  flexboxes,
  FlexboxesProps,
  height,
  HeightProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from "@xstyled/styled-components";

export default styled.button<
  BorderRadiusProps &
    BackgroundColorProps &
    SpaceProps &
    HeightProps &
    WidthProps &
    FlexboxesProps
>`
  display: flex;
  background: none;
  &:focus {
    outline: none;
  }

  ${compose(borderRadius, backgroundColor, height, width, space, flexboxes)}
`;
