import create from "zustand";
import { io, Socket } from "socket.io-client";
import { Settings } from "types";

interface AppStoreState {
  socket: Socket;
  settings: Settings | Record<string, never>;
  setSettings: (settings: Settings) => void;
}

export default create<AppStoreState>()((set) => ({
  socket: io("https://devapi.solagame.io"),
  settings: {},
  setSettings: (settings) => set({ settings }),
}));
