import React from "react";
import { Preflight, ThemeProvider } from "@xstyled/styled-components";
import theme from "../theme";
import GameContainer from "./GameContainer/GameContainer";
import Header from "./Header/Header";
import AllBets from "./AllBets";
import { FlexDiv, Image } from "basecomponents";
import api from "../api";
import useAppStore from "../hooks/useAppStore";
import { Settings } from "types";
import useBettingResults from "../hooks/useBettingResults";

import PenguinBg1 from "jsx:images/penguin-bg-1.svg";
import PenguinBg2 from "jsx:images/penguin-bg-2.svg";
import Penguin1 from "images/penguin-1.png";
import Penguin2 from "images/penguin-2.png";

async function getSettings() {
  return await api.get("settings").json();
}

export default function App() {
  const { onAnimationFinished, gameResult, setTxId, allBets, leaderboard } =
    useBettingResults();

  getSettings().then((result) => {
    useAppStore.getState().setSettings(result as Settings);
  });
  return (
    <ThemeProvider theme={theme}>
      <Preflight />
      <FlexDiv
        backgroundColor="brand.3"
        flexDirection="column"
        alignItems="center"
        paddingBottom={{ mobile: 70, tablet: 130 }}
        position="relative"
        overflow="hidden"
      >
        <Header leaderboard={leaderboard} />
        <FlexDiv column maxWidth={660} w="100%" zIndex={10}>
          <GameContainer
            gameResult={gameResult}
            setTxId={setTxId}
            onAnimationFinished={onAnimationFinished}
          />
          <AllBets allBets={allBets} />
        </FlexDiv>
        <FlexDiv position="absolute" top={138} right={-80}>
          <PenguinBg1 />
        </FlexDiv>
        <Image
          src={Penguin1}
          position="absolute"
          w={450}
          top={330}
          right={60}
        />
        <FlexDiv position="absolute" top={450} left={-150}>
          <PenguinBg2 />
        </FlexDiv>
        <Image src={Penguin2} position="absolute" w={400} left={60} top={250} />
      </FlexDiv>
    </ThemeProvider>
  );
}
