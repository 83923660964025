import { useEffect, useReducer } from "react";
import { DiceSide } from "types";
import Big from "big.js";
import { assetOptions, possibleRolls } from "../constants";
import useAppStore from "./useAppStore";

function getWinningProbability(roll: number, side: DiceSide) {
  const possibilities =
    side === "UP" ? possibleRolls.max - roll : roll - possibleRolls.min;
  return possibilities / possibleRolls.count;
}
function getRollFromProbability(winProbability: number, side: DiceSide) {
  const possibilities = winProbability * possibleRolls.count;
  const number =
    side === "UP"
      ? possibleRolls.max - possibilities
      : possibilities - possibleRolls.min;
  return Math.round(number);
}
function getRollFromMultiplier(multiplier: number, side: DiceSide) {
  const targetProbability = 0.99 / multiplier;
  return getRollFromProbability(targetProbability, side);
}
function getMultiplierFromBet(roll: number, side: DiceSide) {
  const multiplier = (1 / getWinningProbability(roll, side)) * 0.99;
  return +multiplier.toFixed(4);
}
function getInitialBetDetails() {
  let savedBetDetails: any = localStorage.getItem("betDetails");
  if (savedBetDetails) {
    savedBetDetails = JSON.parse(savedBetDetails);
    return { ...savedBetDetails, amount: Big(savedBetDetails.amount) };
  }
  return {
    side: "UP",
    roll: 4999,
    asset: Object.keys(assetOptions)[0],
    amount: Big(0),
  };
}

const maxDecimals = 2;

export default function useBetDetails() {
  const limit = useAppStore((state) => state.settings.limit);
  const [betDetails, setBetDetails] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    getInitialBetDetails()
  );

  const {
    min = 0,
    max = 0,
    default: defaultBet = 0,
  } = limit ? limit[betDetails.asset] : {};

  useEffect(() => {
    if (limit && betDetails.amount.eq(0)) {
      setBetDetails({ amount: new Big(defaultBet) });
    }
  }, [limit]);

  useEffect(() => {
    localStorage.setItem("betDetails", JSON.stringify(betDetails));
  }, [betDetails]);

  const winChance = +(
    getWinningProbability(betDetails.roll, betDetails.side) * 100
  ).toFixed(2);
  const multiplier = getMultiplierFromBet(betDetails.roll, betDetails.side);

  function setRoll(newRoll: number) {
    let roll = newRoll;
    if (roll >= possibleRolls.max) {
      roll = possibleRolls.max - 1;
    } else if (roll <= possibleRolls.min) {
      roll = possibleRolls.min + 1;
    }
    setBetDetails({ roll });
  }
  function setSide(side: DiceSide) {
    setBetDetails({ side, roll: getRollFromMultiplier(multiplier, side) });
  }
  function setAmount(newAmount: Big) {
    let amount = newAmount.round(maxDecimals);
    if (amount.gt(max)) {
      amount = Big(max);
    } else if (amount.lt(min)) {
      amount = Big(min);
    }
    setBetDetails({ amount });
  }
  function setWinChance(newWinChance) {
    setRoll(getRollFromProbability(newWinChance / 100, betDetails.side));
  }
  function setMultiplier(multiplier) {
    setRoll(getRollFromMultiplier(multiplier, betDetails.side));
  }

  return {
    betDetails,
    setAsset: (asset: keyof typeof assetOptions) => setBetDetails({ asset }),
    setRoll,
    setSide,
    setAmount,
    winChance,
    setWinChance,
    multiplier,
    setMultiplier,
  };
}
