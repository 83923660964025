import styled from "@xstyled/styled-components";
import React, { InputHTMLAttributes, useRef } from "react";

const StyledInput = styled.input`
  color: white;
  font-family: bold;
  font-size: 20px;
  line-height: 22px;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export default function ControlInput({
  onConfirm,
  setIsEditing,
  ...rest
}: {
  onConfirm: (newValue: string) => void;
  setIsEditing: (isEditing: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>) {
  const inputRef = useRef<HTMLInputElement>();

  function onKeyDown(e) {
    // save value with enter key
    if (e.key === "Enter") {
      setIsEditing(false);
      inputRef.current.blur();
    }
  }
  function onBlur(e) {
    onConfirm(e.target.value);
    setIsEditing(false);
  }

  return (
    <StyledInput
      {...rest}
      onFocus={() => setIsEditing(true)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      ref={inputRef}
    />
  );
}
