import React, { useState } from "react";
import { assetOptions } from "../../constants";
import { Button, FlexDiv, SVG, Text } from "basecomponents";
import useAppStore from "../../hooks/useAppStore";
import { BetDetails } from "types";
import { PIPButton } from "@getpip/react-pip-button";

export default function Payment({
  selectedAsset,
  setSelectedAsset,
  betDetails,
  setTxId,
}: {
  selectedAsset: keyof typeof assetOptions;
  setSelectedAsset: (selectedAsset: keyof typeof assetOptions) => void;
  betDetails: BetDetails;
  setTxId: (txId: string) => void;
}) {
  const receiver = useAppStore((state) => state.settings.housePubkey);
  const [showPopup, setShowPopup] = useState(false);

  function onSelect(asset: keyof typeof assetOptions) {
    setSelectedAsset(asset);
    setShowPopup(false);
  }
  return (
    <FlexDiv
      marginBottom={{ mobile: 24, tablet: 30 }}
      position="relative"
      marginTop={18}
    >
      <Button
        backgroundColor="brand.3"
        borderRadius={10}
        marginRight={10}
        padding={14}
        onClick={() => setShowPopup(!showPopup)}
      >
        <SVG as={assetOptions[selectedAsset].icon} w={32} />
      </Button>
      {showPopup && (
        <FlexDiv
          position="absolute"
          backgroundColor="brand.5"
          borderRadius={10}
          column
          padding={8}
          top={70}
        >
          {Object.entries(assetOptions).map(([key, { label, icon }]) => (
            <Button
              key={key}
              onClick={() => onSelect(key as keyof typeof assetOptions)}
              backgroundColor="brand.4"
              borderRadius={10}
              padding={8}
              marginBottom={{ notLastChild: 8 }}
              alignItems="center"
            >
              <SVG as={icon} marginRight={10} w={24} />
              <Text fontSize={14} bold color="brand.2">
                {label}
              </Text>
            </Button>
          ))}
        </FlexDiv>
      )}
      <FlexDiv
        backgroundColor="brand.3"
        flex={1}
        alignItems="center"
        justifyContent="center"
        borderRadius={8}
      >
        <PIPButton
          receiver={receiver}
          amount={betDetails.amount.toFixed()}
          currency={selectedAsset}
          buttonColor="#742BFF"
          memo={JSON.stringify(betDetails)}
          onPayment={({ txId }) => setTxId(txId)}
        />
      </FlexDiv>
    </FlexDiv>
  );
}
