import React, { useState } from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import Button from "../base/Button";
import { motion } from "framer-motion";
import { Leaderboard as LeaderboardType } from "types";

import StarIcon from "jsx:icons/star.svg";
import CloseIcon from "jsx:icons/close.svg";

const timeframeOptions = {
  day7: "7 Days",
  day30: "30 Days",
  alltime: "All-time",
};

export default function Leaderboard({
  leaderboard,
}: {
  leaderboard: LeaderboardType;
}) {
  const [selectedTimeframe, setSelectedTimeframe] = useState(
    Object.keys(timeframeOptions)[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        padding={10}
        backgroundColor="brand.4"
        borderRadius={10}
        marginRight={4}
        onClick={() => setIsOpen(!isOpen)}
      >
        <SVG as={StarIcon} />
      </Button>
      <FlexDiv
        as={motion.div}
        position="fixed"
        right={0}
        top={0}
        backgroundColor="brand.4"
        w={{ mobile: "100%", tablet: 424 }}
        h="100vh"
        zIndex={100}
        padding={40}
        column
        animate={{ right: isOpen ? "0%" : "-100%" }}
        initial={false}
        transition={{ type: "spring", damping: 18 }}
      >
        <FlexDiv justifyContent="space-between" marginBottom={24}>
          <FlexDiv>
            {Object.entries(timeframeOptions).map(([key, label]) => (
              <Button
                key={key}
                onClick={() => setSelectedTimeframe(key)}
                backgroundColor="brand.3"
                py={8}
                borderRadius={100}
                marginRight={{ notLastChild: 4 }}
                w={68}
                justifyContent="center"
              >
                <Text
                  fontSize={14}
                  color={selectedTimeframe === key ? "white" : "brand.2"}
                >
                  {label}
                </Text>
              </Button>
            ))}
          </FlexDiv>
          <Button onClick={() => setIsOpen(false)} alignItems="center">
            <CloseIcon />
          </Button>
        </FlexDiv>
        <FlexDiv
          column
          padding={16}
          backgroundColor="brand.3"
          borderRadius={10}
        >
          {leaderboard?.[selectedTimeframe].map(({ player, profit }, index) => (
            <FlexDiv key={player} marginBottom={{ notLastChild: 12 }}>
              <Text fontSize={16} color="white" marginRight={24}>
                {index + 1}.
              </Text>
              <Text fontSize={16} color="white">{`${player.slice(
                0,
                4
              )}...${player.slice(-4)}`}</Text>
              <Text fontSize={16} bold marginLeft="auto" color="white">
                ${profit.toFixed(2)}
              </Text>
            </FlexDiv>
          ))}
        </FlexDiv>
      </FlexDiv>
    </>
  );
}
