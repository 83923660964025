import React, { useRef } from "react";
import styled from "@xstyled/styled-components";
import { DiceSide, GameResult } from "types";
import { FlexDiv } from "basecomponents";
import ResultReveal from "./ResultReveal";
import { Howl } from "howler";
import { throttle } from "throttle-debounce";

import SolanaIcon from "icons/currencies/solana.svg";

const Slider = styled.input<{ side: "UP" | "DOWN"; roll: number }>`
  appearance: none;
  background-color: brand.5;
  height: 24px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.side === "UP"
      ? "linear-gradient(180deg, #59FFC3 0%, #5800FF 100%)"
      : "linear-gradient(180deg, #FF148F 0%, #360F83 100%)"};
  background-position: ${(props) => (props.side === "UP" ? "right" : "left")};
  background-size: ${({ side, roll }) => {
      const percentage = (roll / 9999) * 100;
      return side === "UP" ? 100 - percentage : percentage;
    }}%
    100%;

  ::-webkit-slider-thumb {
    background: white url(${SolanaIcon}) no-repeat center;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(31, 8, 57, 0.7);
  }
  ::-moz-range-thumb {
    background: white url(${SolanaIcon}) no-repeat center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(31, 8, 57, 0.7);
  }
`;

const scrollAudio = new Howl({ src: ["audio/scroller.mp3"] });

const playAudio = throttle(100, () => {
  scrollAudio.play();
});

export default function MainSlider({
  side,
  roll,
  setRoll,
  gameResult,
  onAnimationFinished,
}: {
  side: DiceSide;
  roll: number;
  setRoll: (roll: number) => void;
  gameResult: GameResult;
  onAnimationFinished: () => void;
}) {
  const parentRef = useRef(null);

  function onChange(e) {
    playAudio();
    setRoll(Number(e.target.value));
  }

  return (
    <FlexDiv position="relative" column marginBottom={32} ref={parentRef}>
      <ResultReveal
        parentWidth={parentRef.current?.clientWidth}
        gameResult={gameResult}
        onAnimationFinished={onAnimationFinished}
      />
      <Slider
        type="range"
        value={roll}
        side={side}
        roll={roll}
        min={1}
        max={9998}
        onChange={onChange}
      />
    </FlexDiv>
  );
}
