import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import ControlButton from "./common/ControlButton";
import ControlContainer from "./common/ControlContainer";
import ControlInput from "./common/ControlInput";

import ArrowUp from "jsx:icons/arrow-up.svg";

export default function WinChance({
  winChance,
  setWinChance,
  incrementChance,
  decrementChance,
}: {
  winChance: string;
  setWinChance: (winChance: number) => void;
  incrementChance: () => void;
  decrementChance: () => void;
}) {
  const [inputValue, setInputValue] = useState(winChance);
  const [isEditing, setIsEditing] = useState(false);

  useLayoutEffect(() => {
    setInputValue(winChance);
  }, [isEditing, winChance]);

  return (
    <FlexDiv column flex={1} marginRight={{mobile: 8, tablet: 24}}>
      <Text fontSize={12} color="brand.2" marginBottom={4}>
        WIN CHANCE
      </Text>
      <ControlContainer>
        <ControlInput
          type={isEditing ? "number" : null}
          value={isEditing ? inputValue : `${inputValue}%`}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          onConfirm={(newValue) => setWinChance(+newValue)}
          setIsEditing={setIsEditing}
        />
        <FlexDiv column h="100%" marginLeft="auto">
          <ControlButton
            borderRadius="0 4px 0 0"
            h="50%"
            onClick={incrementChance}
          >
            <SVG as={ArrowUp} />
          </ControlButton>
          <ControlButton
            borderRadius="0 0 4px 0"
            h="50%"
            onClick={decrementChance}
          >
            <SVG as={ArrowUp} transform="rotate(180deg)" />
          </ControlButton>
        </FlexDiv>
      </ControlContainer>
    </FlexDiv>
  );
}
