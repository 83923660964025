import React, { PropsWithChildren } from "react";
import { FlexDiv } from "basecomponents";

export default function ControlContainer({
  children,
}: PropsWithChildren<{ isDisabled?: boolean }>) {
  return (
    <FlexDiv
      backgroundColor="brand.3"
      padding="2px 2px 2px 24px"
      borderRadius={4}
      h={60}
      alignItems="center"
    >
      {children}
    </FlexDiv>
  );
}
