import React, { useState } from "react";
import { FlexDiv } from "basecomponents";
import RecentResults from "./RecentResults";
import MainSlider from "./MainSlider/MainSlider";
import SideSelector from "./SideSelector";
import BetAmount from "./BetAmount";
import Payment from "./Payment";
import Multiplier from "./Multiplier";
import Roll from "./Roll";
import WinChance from "./WinChance";
import Payout from "./Payout";
import useBetDetails from "../../hooks/useBetDetails";
import { GameResult } from "types";

export default function GameContainer({
  gameResult,
  onAnimationFinished,
  setTxId,
}: {
  gameResult: GameResult;
  onAnimationFinished: () => void;
  setTxId: (txId: string) => void;
}) {
  const [recentResults, setRecentResults] = useState([]);
  const {
    betDetails,
    setAsset,
    setRoll,
    setSide,
    setAmount,
    winChance,
    setWinChance,
    multiplier,
    setMultiplier,
  } = useBetDetails();

  const { roll, side, asset, amount } = betDetails;

  //this function improves your odds by updating roll
  function upgradeOdds() {
    setRoll(side === "UP" ? roll - 1 : roll + 1);
  }
  function downgradeOdds() {
    setRoll(side === "UP" ? roll + 1 : roll - 1);
  }

  function updateRecentResults() {
    setRecentResults([
      { roll: gameResult.result, isWin: !!gameResult.multiply },
      ...recentResults,
    ]);
    onAnimationFinished();
  }

  return (
    <FlexDiv
      padding={{ mobile: "24px 16px", tablet: "40px 60px 60px" }}
      borderRadius={{ tablet: 10 }}
      backgroundColor="brand.4"
      flexDirection="column"
      marginBottom={16}
    >
      <RecentResults recentResults={recentResults} />
      <MainSlider
        roll={roll}
        side={side}
        setRoll={setRoll}
        gameResult={gameResult}
        onAnimationFinished={updateRecentResults}
      />
      <SideSelector selectedSide={side} setSelectedSide={setSide} roll={roll} />
      <BetAmount
        selectedAsset={asset}
        betAmount={amount}
        setBetAmount={setAmount}
      />
      <Payment
        selectedAsset={asset}
        setSelectedAsset={setAsset}
        betDetails={betDetails}
        setTxId={setTxId}
      />
      <FlexDiv marginBottom={{ mobile: 16, tablet: 24 }} h={80}>
        <Multiplier
          multiplier={multiplier.toString()}
          setMultiplier={setMultiplier}
          incrementMultiplier={downgradeOdds}
          decrementMultiplier={upgradeOdds}
        />
        <Roll
          selectedSide={side}
          setSelectedSide={setSide}
          roll={roll}
          setRoll={setRoll}
        />
      </FlexDiv>
      <FlexDiv h={80}>
        <WinChance
          winChance={winChance.toString()}
          setWinChance={setWinChance}
          incrementChance={upgradeOdds}
          decrementChance={downgradeOdds}
        />
        <Payout
          selectedAsset={asset}
          multiplier={multiplier}
          betAmount={amount}
        />
      </FlexDiv>
    </FlexDiv>
  );
}
