import React from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import styled from "@xstyled/styled-components";
import { assetOptions } from "../constants";
import { AllBetsResult } from "types";

const StyledTable = styled.table`
  font-family: regular;
  border-spacing: 0 20px;
`;
const StyledTh = styled.th`
  font-weight: normal;
  font-size: 14px;
  color: brand.2;
  &:first-child {
    text-align: left;
  }
  &:nth-child(2) {
    text-align: left;
    @media (max-width: tablet) {
      display: none;
    }
  }
  &:last-child {
    text-align: right;
  }
`;
const StyledTd = styled.td` 
  font-size: 16px;
  color: brand.2;
  text-align: center;

  &:nth-child(2) {
    @media (max-width: tablet) {
      display: none;
    }
  }
  &:first-child{
    text-align: left;
  }
  &:last-child{
    text-align: right;
`;

export default function AllBets({ allBets }: { allBets: AllBetsResult[] }) {
  const columns: {
    label: string;
    getCellContent: (result: AllBetsResult) => string | JSX.Element;
  }[] = [
    {
      label: "User",
      getCellContent: ({ user }) => `${user.slice(0, 4)}...${user.slice(-4)}`,
    },
    {
      label: "Wager",
      getCellContent: ({ asset, amount }) => (
        <FlexDiv justifyContent="left">
          <SVG as={assetOptions[asset].icon} w={16} marginRight={4} />
          <Text bold color="white" fontSize={16}>
            ${+(+amount).toFixed(2)}
          </Text>
        </FlexDiv>
      ),
    },
    {
      label: "Multiplier",
      getCellContent: ({ multiply }) => `x${multiply.toFixed(2)}`,
    },
    {
      label: "Payout",
      getCellContent: ({ asset, payout, txhash }) => (
        <FlexDiv
          justifyContent="right"
          display="inline-flex"
          as={txhash && "a"}
          href={`https://solscan.io/tx/${txhash}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <SVG as={assetOptions[asset].icon} w={16} marginRight={4} />
          <Text bold color={payout ? "green.2" : "brand.2"} fontSize={16}>
            ${+(+payout).toFixed(2)}
          </Text>
        </FlexDiv>
      ),
    },
  ];

  return (
    <FlexDiv
      backgroundColor="brand.4"
      padding={{ mobile: 16, tablet: 24 }}
      borderRadius={{ tablet: 10 }}
      column
    >
      <Text color="white" bold fontSize={20} marginBottom={16}>
        All Bets
      </Text>
      <StyledTable>
        <thead>
          <tr>
            {columns.map(({ label }) => (
              <StyledTh key={label}>{label}</StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {allBets.map((row) => (
            <tr key={row.round}>
              {columns.map(({ label, getCellContent }) => (
                <StyledTd key={label}>{getCellContent(row)}</StyledTd>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </FlexDiv>
  );
}
