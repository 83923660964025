import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import { assetOptions } from "../../constants";
import ControlButton from "./common/ControlButton";
import Big from "big.js";
import ControlContainer from "./common/ControlContainer";
import ControlInput from "./common/ControlInput";
import useAppStore from "../../hooks/useAppStore";

import ArrowUp from "jsx:icons/arrow-up.svg";

export default function BetAmount({
  selectedAsset,
  betAmount,
  setBetAmount,
}: {
  selectedAsset: keyof typeof assetOptions;
  betAmount: Big;
  setBetAmount: (betAmount: Big) => void;
}) {
  const limit = useAppStore((state) => state.settings.limit);
  const [inputValue, setInputValue] = useState(betAmount.toFixed());
  const [isEditing, setIsEditing] = useState(false);

  useLayoutEffect(() => {
    setInputValue(betAmount.toFixed());
  }, [isEditing, betAmount]);

  const { default: defaultBet = 0 } = limit ? limit[selectedAsset] : {};

  return (
    <ControlContainer>
      <SVG as={assetOptions[selectedAsset].icon} marginRight={16} w={24} />
      <Text fontSize={20} bold color="white">
        $
      </Text>
      <ControlInput
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        onConfirm={(newValue) => setBetAmount(Big(newValue))}
        setIsEditing={setIsEditing}
      />
      <ControlButton
        borderRadius="4px 0 0 4px"
        marginLeft="auto"
        h="100%"
        onClick={() => setBetAmount(betAmount.div(2))}
      >
        ½
      </ControlButton>
      <ControlButton
        h="100%"
        mx={2}
        onClick={() => setBetAmount(betAmount.times(2))}
      >
        2x
      </ControlButton>
      <FlexDiv column h="100%">
        <ControlButton
          borderRadius="0 4px 0 0"
          h="50%"
          onClick={() => setBetAmount(betAmount.plus(defaultBet))}
        >
          <SVG as={ArrowUp} />
        </ControlButton>
        <ControlButton
          borderRadius="0 0 4px 0"
          h="50%"
          onClick={() => setBetAmount(betAmount.minus(defaultBet))}
        >
          <SVG as={ArrowUp} transform="rotate(180deg)" />
        </ControlButton>
      </FlexDiv>
    </ControlContainer>
  );
}
