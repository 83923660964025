import React, { useEffect, useState } from "react";
import { FlexDiv, Text } from "basecomponents";
import styled from "@xstyled/styled-components";
import { possibleRolls } from "../../../constants";
import { AnimatePresence, motion } from "framer-motion";
import { GameResult } from "types";
import { Howl } from "howler";

import ResultBackground from "images/result-background.png";

const StyledReveal = styled(FlexDiv)`
  background: url(${ResultBackground}) no-repeat;
  background-size: cover;
`;

const rollAudio = new Howl({ src: ["audio/rolling.mp3"] });
const winAudio = new Howl({ src: ["audio/win.mp3"] });
const loseAudio = new Howl({ src: ["audio/lose.mp3"] });

const componentWidth = 108;
export default function ResultReveal({
  parentWidth,
  gameResult,
  onAnimationFinished,
}: {
  parentWidth: number;
  gameResult: GameResult;
  onAnimationFinished: () => void;
}) {
  const [rollTo, setRollTo] = useState(null);
  const revealResult = rollTo || rollTo === 0;

  useEffect(() => {
    if (gameResult) {
      rollAudio.once("end", function () {
        if (gameResult.multiply) {
          winAudio.play();
        } else {
          loseAudio.play();
        }
      });
      rollAudio.play();
      setRollTo(gameResult.result);
    }
  }, [gameResult]);

  function getRollPosition() {
    const position = (parentWidth / possibleRolls.max) * rollTo;
    return Math.round(position - componentWidth / 2);
  }

  return (
    <AnimatePresence>
      {revealResult && (
        <StyledReveal
          as={motion.div}
          paddingBottom={18}
          position="absolute"
          alignItems="center"
          justifyContent="center"
          bottom={2}
          h={74}
          w={componentWidth}
          left={0}
          initial={{
            opacity: 0,
            left: rollTo < possibleRolls.count / 2 ? parentWidth : 0,
          }}
          animate={{ opacity: 1, left: getRollPosition() }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            duration: 0.8,
            bounce: 0.15,
            delay: 1.4,
          }}
          onAnimationComplete={() => {
            if (revealResult && gameResult) {
              onAnimationFinished();
              setTimeout(() => setRollTo(null), 1000);
            }
          }}
        >
          <Text bold fontSize={28} color="brand.3">
            {rollTo}
          </Text>
        </StyledReveal>
      )}
    </AnimatePresence>
  );
}
