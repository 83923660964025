import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { FlexDiv, SVG, Text } from "basecomponents";
import ControlButton from "./common/ControlButton";
import ControlContainer from "./common/ControlContainer";
import ControlInput from "./common/ControlInput";

import ArrowUp from "jsx:icons/arrow-up.svg";

export default function Multiplier({
  multiplier,
  setMultiplier,
  incrementMultiplier,
  decrementMultiplier,
}: {
  multiplier: string;
  setMultiplier: (multiplier: number) => void;
  incrementMultiplier: () => void;
  decrementMultiplier: () => void;
}) {
  const [inputValue, setInputValue] = useState(multiplier);
  const [isEditing, setIsEditing] = useState(false);

  useLayoutEffect(() => {
    setInputValue(multiplier);
  }, [isEditing, multiplier]);

  return (
    <FlexDiv column flex={1} marginRight={{mobile: 8, tablet: 24}}>
      <Text fontSize={12} color="brand.2" marginBottom={4}>
        MULTIPLIER
      </Text>
      <ControlContainer>
        <Text fontSize={20} bold color="white">
          x
        </Text>
        <ControlInput
          value={inputValue}
          onConfirm={(newMultiplier) => setMultiplier(+newMultiplier)}
          setIsEditing={setIsEditing}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          type="number"
        />
        <FlexDiv column h="100%" marginLeft="auto">
          <ControlButton
            borderRadius="0 4px 0 0"
            h="50%"
            onClick={incrementMultiplier}
          >
            <SVG as={ArrowUp} />
          </ControlButton>
          <ControlButton
            borderRadius="0 0 4px 0"
            h="50%"
            onClick={decrementMultiplier}
          >
            <SVG as={ArrowUp} transform="rotate(180deg)" />
          </ControlButton>
        </FlexDiv>
      </ControlContainer>
    </FlexDiv>
  );
}
