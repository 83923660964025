import styled, {
  backgroundColor,
  BackgroundColorProps,
  border,
  borderRadius,
  BorderRadiusProps,
  compose,
  flexboxes,
  FlexboxesProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  sizing,
  SizingProps,
  space,
  SpaceProps,
} from "@xstyled/styled-components";

export default styled.div<
  SpaceProps &
    FlexboxesProps &
    BackgroundColorProps &
    SizingProps &
    BorderRadiusProps &
    LayoutProps & { column?: boolean }
>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  ${compose(space, flexboxes, backgroundColor, sizing, borderRadius, layout)}
`;
