import React from "react";
import { Button, FlexDiv, Text } from "basecomponents";
import styled, { th } from "@xstyled/styled-components";
import { DiceSide } from "types";

const SelectorButton = styled(Button)<{ side: DiceSide; isSelected: boolean }>`
  position: relative;
  height: 40px;
  background: none;
  align-items: center;
  justify-content: center;

  width: 108px;
  @media (min-width: tablet) {
    width: 120px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${(props) => {
      if (!props.isSelected) {
        return th.color("brand.3");
      }
      return props.side === "UP"
        ? "linear-gradient(180deg, #59FFC3 0%, #5800FF 100%)"
        : "linear-gradient(180deg, #FF148F 0%, #360F83 100%)";
    }};
    transform: skewX(${(props) => (props.side === "UP" ? 20 : -20)}deg);
    border-radius: 2px;
  }
`;
const ButtonSpan = styled(Text)`
  z-index: 1;
`;

export default function SideSelector({
  selectedSide,
  setSelectedSide,
  roll,
}: {
  selectedSide: DiceSide;
  setSelectedSide: (selectedSide: DiceSide) => void;
  roll: number;
}) {
  return (
    <FlexDiv
      justifyContent="space-between"
      alignItems="center"
      marginBottom={24}
      px={7}
    >
      <SelectorButton
        side="DOWN"
        onClick={() => setSelectedSide("DOWN")}
        isSelected={selectedSide === "DOWN"}
      >
        <ButtonSpan fontSize={16} color="white" bold>
          UNDER
        </ButtonSpan>
      </SelectorButton>
      <Text color="white" fontSize={{ mobile: 26, tablet: 32 }} bold>
        {roll}
      </Text>
      <SelectorButton
        side="UP"
        onClick={() => setSelectedSide("UP")}
        isSelected={selectedSide === "UP"}
      >
        <ButtonSpan fontSize={16} color="white" bold>
          OVER
        </ButtonSpan>
      </SelectorButton>
    </FlexDiv>
  );
}
